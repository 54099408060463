// @ts-nocheck
import { createApp, createVNode, render } from 'vue';
import { VueQueryPlugin } from 'vue-query';

export const renderComponentWithVNode = ({ el, component, props }: Record<string, unknown>): (() => void) => {
  let vnode = createVNode(component, props);
  render(vnode, el);

  return () => {
    // destroy vnode
    render(null, el);
    vnode = undefined;
  };
};

export const renderComponentWithApp = ({ el, component, props }: Record<string, unknown>): (() => void) => {
  let app = createApp(component, props);

  VueQueryPlugin.install(app, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          retry: false
        }
      }
    }
  });
  app.mount(el);

  return () => {
    // destroy app/component
    app?.unmount();
    app = undefined;
  };
};
