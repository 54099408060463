import useManifoldCSS from '@manifoldxyz/css-reset';
import MWalletIdentity from '@/exports/MWalletIdentity.vue';
import { renderComponentWithApp } from './mount';

// track app-component Vue instances for proper destroying later
const renderedComponents = new Map();
let widgetAttributeChangeObserver: MutationObserver;
let bodyChangeObserver: MutationObserver;

/*
 * Replaces the el with a rendered Vue component (as its own Vue app)
 * then monitors that div for any attribute changes, that way we know when
 * to automatically destroy + rerender the widget with new props.
 * This also handles parsing the data-attrs into typechecked Vue props.
 */
const replaceWithWidget = (el: HTMLElement) => {
  // grab the DOM element's data- properties to use as propsData
  const data = el.dataset;

  // be sure to give proper fallback type
  const wallet = ('wallet' in data) ? data.wallet : undefined;
  let propsData = {};
  if (wallet) {
    propsData = {
      wallet: wallet
    };
  }

  // render the component
  const renderedComponent = renderComponentWithApp({
    el: el,
    // Entry point is MWalletIdentity
    component: MWalletIdentity,
    props: propsData
  });
  renderedComponents.set(el, renderedComponent);

  // observe any attribute changes and rerender accordingly
  const config = {
    attributes: true,
    childList: false,
    subtree: false
  };
  // anytime we change attributes on the element itself
  widgetAttributeChangeObserver.observe(el, config);
};

/*
 * Checks if the el has a corresponding rendered Vue component in memory.
 * If it does, we unmount the Vue component and destroy its data in memory.
 */
const destroyPotentialWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component for memory's sake
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

/* Mutation Handlers */

/*
 * When a previously rendered widget has an attribute changed we need to
 * destroy the potential widget that was already rendered in that div
 * and then render a brand new widget that uses the new data as props in
 * its place.
 */
const handleWidgetAttributeChange = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName !== 'data-v-app') {
      // destroy pre-existing app-component before replacing it with new one
      destroyPotentialWidget(mutation.target as HTMLElement);
      replaceWithWidget(mutation.target as HTMLElement);
    }
  });
};

/*
 * Whenever the body changes we want to see:
 *
 * 1. If a div with data-widget was added to the DOM
 * 2. If a div with data-widget was removed from the DOM
 * 3. If a div dynamically had the data-widget attr added
 *
 * If any are true, we want to render or destroy the widget in that div.
 */
const handleBodyChanges = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    // dynamically added a node that may have a div with our data-widget attribute inside
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === 'm-wallet-identity') {
        replaceWithWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.pop(); // last element is root el which we've already checked above

      children.forEach((child: any) => {
        if ((child as HTMLElement)?.dataset?.widget === 'm-wallet-identity') {
          replaceWithWidget(child as HTMLElement);
        }
      });
    });

    // dynamically removed a node that had our widget rendered into it
    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (htmlEl?.dataset?.widget === 'm-wallet-identity') {
        destroyPotentialWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child: any) => {
        if ((child as HTMLElement)?.dataset?.widget === 'm-wallet-identity') {
          destroyPotentialWidget(child as HTMLElement);
        }
      });
    });

    // dynamically added the data-widget attribute
    if (mutation.type === 'attributes' && mutation.attributeName === 'data-widget') {
      const htmlEl = mutation.target as HTMLElement;
      if (htmlEl?.dataset?.widget === 'm-wallet-identity') {
        replaceWithWidget(htmlEl);
      }
    }
  });
};

/*
 * Recursively finds and returns all children as an array.
 * The last element of the array is the root element.
 */
const getAllChildren = (htmlElement: Element): Element[] => {
  if (!htmlElement.children || htmlElement.children?.length === 0) {
    return [htmlElement];
  }

  const allChildElements = [];

  for (let i = 0; i < htmlElement.children.length; i++) {
    const children = getAllChildren(htmlElement.children[i]);
    if (children) allChildElements.push(...children);
  }
  allChildElements.push(htmlElement);

  return allChildElements;
};

const main = (): void => {
  // MutationObserver gets reused by every rendered component
  widgetAttributeChangeObserver = new window.MutationObserver(handleWidgetAttributeChange);

  // manually sweep the DOM for pre-existing widget divs that need replacing
  const elements = document.querySelectorAll('[data-widget="m-wallet-identity"]');
  elements.forEach((el: Element) => {
    replaceWithWidget(el as HTMLElement);
  });

  // Listen for new widget divs being added/removed to the body of the DOM.
  // Also listen for divs dynamically adding the data-widget attribute.
  const bodyNode = document.querySelector('body');
  if (bodyNode && !bodyChangeObserver) {
    const config = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['data-widget']
    };

    bodyChangeObserver = new window.MutationObserver(handleBodyChanges);
    bodyChangeObserver.observe(bodyNode, config);
  }
};

if (window) {
  useManifoldCSS({ reset: 'none', styles: 'vars' }, 'creator-identity-widget');
  const alreadyLoaded = performance
    .getEntriesByType('navigation')
    .every((e) => (e as PerformanceNavigationTiming).loadEventEnd);

  if (alreadyLoaded) {
    // the `load` event already fired so we're ready to do main() instantly
    main();
  } else {
    // we only want to call main() after the page `load` fires
    window.addEventListener('load', main);
  }
}
